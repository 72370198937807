import React from 'react';
import { connect } from 'react-redux';

import find from 'lodash/find';
import intersection from 'lodash/intersection';

import { caseSelector } from 'utils/helpers';
import { createUniqueId } from 'utils/id'
import { blobToDataUri } from 'utils/file'

import { addLocalFile } from 'actions'

import {
  getFieldSelection,
  getFieldSelectionDisplay,
  getFieldSelectionImageUrl,
  getUserFieldSelections
} from 'selectors'

import { Translate } from 'containers/translates';

import { Nav, NavItem, NavLink } from 'reactstrap';

import Modal, { VERTICAL_MODAL_POSITIONS } from 'components/modals/Modal';
import CreateLocalSelectionForm from 'components/forms/CreateLocalSelectionForm'

import {
  ProductOptionActivitySelectionFilter
} from 'components/Product/ProductOptionSelectionFilter';

import DropdownWidget from './DropdownWidget'


import ActivityIdContext from '../contexts/ActivityIdContext'
import './SelectionField.scss';

const SelectedImageSelection = connect((state, ownProps) => {
  const { selectionId, value } = ownProps
  return {
    selection: selectionId && getFieldSelection(state, selectionId),
    selectionName: selectionId && getFieldSelectionDisplay(state, {
      selectionId,
      value
    }),
    selectionImageUrl: selectionId && getFieldSelectionImageUrl(state, {
      selectionId,
      value
    }),
  }
})(function _SelectedImageSelection({ selection = undefined, selectionName, selectionImageUrl, placeholder = '', onClick = undefined, onReset }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: 10,
        backgroundColor: "white",
        border: "1px solid #ccc",
        borderRadius: 3,
        cursor: onClick ? "pointer" : "default"
      }}
    >
      <div onClick={onClick} style={{ flex: 1, display: "flex" }}>
        {
          selection !== undefined ? (
            <React.Fragment>
              {
                selectionImageUrl &&
                <img
                  src={selectionImageUrl}
                  style={{
                    width: 60,
                    height: 50,
                    objectFit: 'contain'
                  }}
                />
              }
              <div className="ml-2">
                <div style={{ wordBreak: "break-all" }}><strong>{selectionName}</strong></div>
                {selection && <div style={{ fontSize: 14 }}>{selection.categoryLabel}</div>}
              </div>
            </React.Fragment>
          ) : (
            <span className="text-muted">{placeholder}</span>
          )
        }
      </div>

      {selection && onReset &&
        <button onClick={onReset} style={{ padding: 4, background: "transparent", border: "none", }}>
          <i className="fa fa-times-circle" />
        </button>
      }

      {
        onClick && (
          <div style={{
            marginLeft: 8,
            padding: 4
          }} onClick={onClick}>
            <i className="fa fa-caret-down" />
          </div>
        )
      }
    </div>
  )
})

function FieldSelectionWrapper({ children }) {
  return (
    <label className="SelectionField_Item">
      {children}
    </label>
  )
}

function FieldSelectionRadio({ name, identifier, isSelected, onChange }) {
  return (
    <FieldSelectionWrapper>
      <input type="radio" onChange={onChange} checked={isSelected} /> <span>{name} {
        identifier &&
        `(${identifier})`
      }</span>
    </FieldSelectionWrapper>
  );
}

const FieldSelectionImage = connect((state, { selectionId, value }) => ({
  selectionImageUrl: getFieldSelectionImageUrl(state, { selectionId, value }),
  selectionDisplay: getFieldSelectionDisplay(state, { selectionId, value }),
  selection: getFieldSelection(state, selectionId),
}))(function _FieldSelectionImage({ name, selectionImageUrl, selectionDisplay, selection, identifier, isSelected, onChange, image, ...props }) {
  // console.log({ selection })

  return (
    <FieldSelectionWrapper>
      <input type="radio" className="invisible" onChange={onChange} checked={isSelected} />
      <div>
        {
          selectionImageUrl ? (
            <img className="image-preview" src={selectionImageUrl} role="presentation" />
          ) : (
            <div className="image-preview --placeholder">
              <i
                className="fa fa-image fa-2x"
                style={{
                  marginTop: -10
                }}
              />
            </div>
          )
        }

        <div className="display">{selectionDisplay}</div>
        {selection.categoryLabel && <div className="category-label">{selection.categoryLabel}</div>}
      </div>
    </FieldSelectionWrapper>
  );
})


function FieldSelectionFactory({ widget, ...props }) {
  return caseSelector({
    images: () => (
      <FieldSelectionImage {...props} />
    ),
  }, () => (
    <FieldSelectionRadio {...props} />
  ))(widget)();
}

const ConnectedFieldSelectionFactory = connect((state, { selectionId }) => ({
  ...(find(state.fieldSelections, x => x.id === selectionId) || {}),
}))(FieldSelectionFactory);


function FieldSelectionsWrapper({ children }) {
  return (
    <div className="SelectionField">
      <div className="SelectionField_Items">
        {children}
      </div>
    </div>
  )
}

const InnerSelectionField = connect((state, { selectionIds, input, selectionFilters = [], selectedTagIds, activityId: selectedActivityId, ...props }) => {

  const customerSelectionIds = getUserFieldSelections(state).map((x) => x.id)

  const onlyStandardSelectionIds = state.fieldSelections
    .filter(({ id }) => selectionIds.indexOf(id) !== -1)
    .filter(({ isTemplate }) => !isTemplate)
    .map(({ id }) => id)

  const selections = state.fieldSelections
    .filter((x) => onlyStandardSelectionIds.indexOf(x.id) !== -1)

  let activeSelectionIds = onlyStandardSelectionIds.filter((id) => customerSelectionIds.indexOf(id) === -1)

  let useActivitiesFilter = selectionFilters.indexOf('activities') !== -1;
  const haveSelectedActivity = selectedActivityId !== null;
  let activityIds = [];

  if (useActivitiesFilter) {
    activityIds = selections.reduce((accum, x) => [...accum, ...x.activityIds], []);

    if (useActivitiesFilter = activityIds.length > 0) {
      if (haveSelectedActivity) {
        activeSelectionIds = selections
          .filter(x => activeSelectionIds.indexOf(x.id) !== -1)
          .filter(x => (
            x.activityIds.indexOf('*') !== -1 ||
            x.activityIds.indexOf(selectedActivityId) !== -1
          ))
          .map(x => x.id);
      }
      // else {
      //   activeSelectionIds = [];
      // }
    }
  }

  let useTagsFilter = selectionFilters.indexOf('tags') !== -1;
  const haveSelectedTag = selectedTagIds.length > 0;
  let tagIds = [];

  if (useTagsFilter) {
    tagIds = selections
      .filter(x => activeSelectionIds.indexOf(x.id) !== -1)
      .reduce((accum, x) => [...accum, ...x.tagIds], []);

    if (useTagsFilter = tagIds.length > 0) {
      if (haveSelectedTag) {
        activeSelectionIds = selections
          .filter(x => activeSelectionIds.indexOf(x.id) !== -1)
          .filter(x => intersection(x.tagIds, selectedTagIds).length == selectedTagIds.length)
          .map(x => x.id);
      }
    }
  }

  const activeSelections = selections.filter(({ id }) => activeSelectionIds.indexOf(id) !== -1)

  return {
    useActivitiesFilter,
    activityIds,
    selectedActivityId,
    useTagsFilter,
    tagIds,
    tags: state.tags
      .filter((tag) => tagIds.indexOf(tag.id) !== -1)
      .map((tag) => ({
        ...tag,
        category: find(state.tagCategories, (tc) => tc.id === tag.categoryId)
      })),
    activeSelectionIds,
    activeSelections,
    activeSelectionTagIds: activeSelections.reduce((tagIds, selection) => ([
      ...tagIds,
      ...selection.tagIds
    ]), []),
    selectedSelection: find(state.fieldSelections, (selection) => selection.id === input.selectionId)
  }
})(function _InnerSelectionField({
  activeSelectionIds, activeSelections, activeSelectionTagIds, input, selectedSelection,
  useActivitiesFilter, activityIds, selectedActivityId,
  useTagsFilter, tagIds, tags, selectedTagIds,
  onChange, onReset,
  onChangeTagFilter,
  onResetTagFilter,
  widget,
  ...props
}) {

  return (
    <Translate ns="product">
      {({ t }) => (
        <div>
          <div>
            {
              (useActivitiesFilter || useTagsFilter) && (
                <div className="my-3">
                  {
                    useActivitiesFilter && (
                      <React.Fragment>
                        <h3>{t('productFields.filters.header')}</h3>
                        <ProductOptionActivitySelectionFilter
                          {...{
                            activityIds
                          }}
                        />
                      </React.Fragment>
                    )
                  }
                  {
                    useTagsFilter &&
                    <React.Fragment>
                      <div className="d-flex align-items-center">
                        <h3>{t('productFields.filters.header')}</h3>
                        <button
                          className="btn btn-sm m-1 btn-outline-dark"
                          style={{ marginLeft: 'auto' }}
                          disabled={selectedTagIds.length === 0}
                          onClick={() => {
                            onChangeTagFilter([])
                          }}
                        >
                          {t('productFields.filters.resetButton.label')}
                        </button>
                      </div>
                      <div>
                        {
                          tags.map(
                            (tag) => ({
                              ...tag,
                              isSelected: selectedTagIds.indexOf(tag.id) !== -1
                            })
                          ).map((tag, tagIndex) => (
                            <React.Fragment key={tag.id}>
                              {
                                (tagIndex === 0 || tag.categoryId !== tags[tagIndex - 1].categoryId) && (
                                  <div className="my-1">{tag.category.name}: </div>
                                )
                              }
                              <button
                                className={`btn btn-sm m-1 btn-primary`}
                                onClick={() => {
                                  onChangeTagFilter(tag.isSelected ? selectedTagIds.filter(x => x !== tag.id) : [
                                    ...selectedTagIds,
                                    tag.id,
                                  ])
                                }}
                                disabled={activeSelectionTagIds.indexOf(tag.id) === -1}
                              >
                                <i className={`fa ${tag.isSelected ? "fa-check" : ""}`} />
                                {tag.name}
                              </button>

                            </React.Fragment>
                          ))
                        }
                      </div>
                    </React.Fragment>
                  }
                </div>
              )
            }

          </div>
          {
            (
              <FieldSelectionsWrapper>
                {
                  caseSelector([[
                    ["radios", "images"],
                    () => (
                      activeSelectionIds.map((selectionId) => {
                        return (
                          <ConnectedFieldSelectionFactory
                            key={selectionId}
                            selectionId={selectionId}
                            isSelected={input.selectionId === selectionId}
                            onChange={() => onChange({ selectionId })}
                            widget={widget}
                          />
                        )
                      })
                    )
                  ], [
                    ["dropdown"],
                    () => (
                      <DropdownWidget
                        selectedSelectionId={input.selectionId}
                        selections={activeSelections}
                        onChange={(selectionId) => onChange({ selectionId })}
                      />
                    )
                  ]],
                    () => <div>{"Not implemented?"}</div>
                  )(widget)()
                }

              </FieldSelectionsWrapper>
            )
          }
        </div>
      )}
    </Translate>
  )
});


const CustomerSelections = connect((state) => ({
  customerSelectionIds: getUserFieldSelections(state).map((x) => x.id),
  localFiles: state.userSettings.localFiles,
}))(class _CustomerSelections extends React.Component {
  render() {
    const {
      onChange, onReset,
      widget,
      input,
      customerSelectionIds,
      localFiles
    } = this.props


    const selections = [
      ...localFiles.map((localFile) => ({
        selectionId: localFile.selectionTemplateId,
        value: localFile.id,
      })),
      ...customerSelectionIds.map((selectionId) => ({
        selectionId,
      })),
    ]

    return (
      <div className="my-3">
        <FieldSelectionsWrapper>
          {selections.map(({ selectionId, value }, selectionIndex) => {
            console.log({ selectionId, value, selectionIndex, input, selections })
            return (
              <FieldSelectionImage
                key={selectionIndex}
                selectionId={selectionId}
                value={value}
                isSelected={(
                  selectionId === input.selectionId &&
                  value === input.value
                )}
                onChange={() => onChange({
                  selectionId,
                  value
                })}
              />
            )
          })}
        </FieldSelectionsWrapper>
      </div>
    )
  }
})


const TAB_STANDARD = 'TAB_STANDARD'
const TAB_CUSTOMER = 'TAB_CUSTOMER'
const TAB_UPLOAD = 'TAB_UPLOAD'

const ImageSelectionInnerFieldContainer = connect(
  (state, { input, selectionTemplateIds, ...ownProps }) => {
    return {
      selectedSelection: find(state.fieldSelections, x => x.id === input.selectionId),
      selectionTemplates: (
        selectionTemplateIds ?
          state.fieldSelections.filter(x => selectionTemplateIds.indexOf(x.id) !== -1) :
          []
      ),
      customerSelectionIds: getUserFieldSelections(state).map((x) => x.id),
      localFiles: state.userSettings.localFiles
    }
  },
  {
    addLocalFile
  }
)(class _ImageSelectionInnerFieldContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      currentTab: TAB_STANDARD,
      newImage: null
    };
  }

  toggleModal = () => {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    })
  }

  onChange = (...args) => {
    this.props.onChange(...args)
    setTimeout(this.toggleModal, 400)
  }



  static getDerivedStateFromProps(nextProps, prevState) {

    const {
      selectionTemplates,
      customerSelectionIds,
      localFiles
    } = nextProps

    const haveCustomerSelections = (customerSelectionIds.length + localFiles.length > 0)

    if (prevState.haveCustomerSelections !== haveCustomerSelections) {
      return {
        haveCustomerSelections,
        currentTab: haveCustomerSelections ? TAB_CUSTOMER : TAB_STANDARD
      }
    }

    return null
  }

  render() {

    const {
      selectedSelection,
      input,
      selectionTemplates,
      customerSelectionIds,
      localFiles,
      canCreateSelections,
      ...props
    } = this.props


    const { haveCustomerSelections } = this.state


    return (
      <React.Fragment>

        <SelectedImageSelection
          selectionId={input.selectionId}
          value={input.value}
          placeholder={this.props.placeholder}
          onClick={this.toggleModal}
          onReset={props.onReset}
        />

        <Modal
          isOpen={this.state.modalIsOpen}
          onClose={this.toggleModal}
          verticalPosition={VERTICAL_MODAL_POSITIONS.TOP}
          style={{
            width: 'calc(100vw - 40px)',
            maxWidth: 1024,
          }}
        >
          <div>
            <h2>{props.label}</h2>

            {
              canCreateSelections ? (
                <React.Fragment>
                  <Translate ns="product.productFields.nav">
                    {({ t }) => (
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            href="javascript:;"
                            active={this.state.currentTab === TAB_STANDARD}
                            onClick={() => this.setState({ currentTab: TAB_STANDARD })}
                          >
                            {t('standard')}
                          </NavLink>
                        </NavItem>
                        {
                          haveCustomerSelections &&
                          <NavItem>
                            <NavLink
                              href="javascript:;"
                              active={this.state.currentTab === TAB_CUSTOMER}
                              onClick={() => this.setState({ currentTab: TAB_CUSTOMER })}
                            >
                              {t('customer')}
                            </NavLink>
                          </NavItem>
                        }
                        <NavItem>
                          <NavLink
                            href="javascript:;"
                            active={this.state.currentTab === TAB_UPLOAD}
                            onClick={() => this.setState({ currentTab: TAB_UPLOAD })}
                          >
                            <i className="fa fa-upload fa-fw" />
                            {t('upload')}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    )}
                  </Translate>

                  <div className="mt-2">
                    {{
                      [TAB_STANDARD]: () => (
                        <ActivityIdContext.Consumer>
                          {(activityId) => (
                            <InnerSelectionField
                              {...props}
                              input={input}
                              onChange={this.onChange}
                              activityId={activityId}
                            />
                          )}
                        </ActivityIdContext.Consumer>
                      ),
                      [TAB_CUSTOMER]: () => (
                        <CustomerSelections
                          {...props}
                          onChange={this.onChange}
                          input={input}
                        />
                      ),
                      [TAB_UPLOAD]: () => (
                        <Translate ns="product.productFields.localSelections.creationForm">
                          {({ t }) => (
                            <CreateLocalSelectionForm
                              t={t}
                              onSubmit={({ selectionTemplateId, file, name }) => {
                                blobToDataUri(file)
                                  .then(
                                    (dataUrl) => {
                                      const id = createUniqueId()
                                      this.props.addLocalFile({
                                        id,
                                        name,
                                        dataUrl,
                                        selectionTemplateId
                                      })

                                      setTimeout(() => {
                                        this.onChange({
                                          selectionId: selectionTemplateId,
                                          value: id,
                                        })
                                      }, 100)

                                    }
                                  )

                              }}
                              selectionTemplates={selectionTemplates}
                            />
                          )}
                        </Translate>
                      )
                    }[this.state.currentTab]()}
                  </div>
                </React.Fragment>
              ) : (
                <ActivityIdContext.Consumer>
                  {(activityId) => (
                    <InnerSelectionField
                      {...props}
                      input={input}
                      onChange={this.onChange}
                      activityId={activityId}
                    />
                  )}
                </ActivityIdContext.Consumer>
              )
            }


          </div>
        </Modal>
      </React.Fragment>
    )
  }
})


function DefaultSelectionInnerFieldContainer(props) {
  return (
    <InnerSelectionField
      {...props}
    />
  )
}

export default class SelectionField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTagIds: [],
    };

    this.onChangeTagFilter = this.onChangeTagFilter.bind(this);
    this.onResetTagFilter = this.onResetTagFilter.bind(this);
  }

  onChangeTagFilter(selectedTagIds) {
    this.setState((state) => ({
      selectedTagIds
    }));
  }

  onResetTagFilter() {
    this.onChangeTagFilter([]);
  }

  maybeChangeToDefault() {
    const { input, defaultSelectionId } = this.props;

    if (input.selectionId === undefined && defaultSelectionId !== undefined) {
      this.props.onChange({ selectionId: defaultSelectionId });
    }
  }

  componentDidUpdate() {
    this.maybeChangeToDefault();
  }

  componentDidMount() {
    this.maybeChangeToDefault();
  }

  render() {

    const innerContainerProps = {
      ...this.props,
      selectedTagIds: this.state.selectedTagIds,
      onChangeTagFilter: this.onChangeTagFilter,
      onResetTagFilter: this.onResetTagFilter,
    }

    return ({
      images: () => (
        <ImageSelectionInnerFieldContainer
          {...innerContainerProps}
        />
      )
    }[this.props.widget] ||
      (
        () => (
          <DefaultSelectionInnerFieldContainer
            {...innerContainerProps}
          />
        )
      )
    )()
  }
}
